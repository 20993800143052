import React, { useEffect, useState } from 'react'
import NavBarGeneral from '../navbar/NavBar'
import { getUserId, getUserRole, getUserToken, logout, validarUsuario } from '../login/UserController';
import { obtenerEjes, obtenerNovedades, obtenerUnidades, obtenerUnidadesPorEje, buscarUnidad, obtenerSecciones, obtenerMyData, obtenerUnidadesGuardadas } from '../../controlador/index.controller';
import { Carousel, Col, Row, Container, Button, Card, Form, Modal, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faMusic, faSearch, faFilter, faSadCry } from '@fortawesome/free-solid-svg-icons';
import NotFound from '../generalComponents/NotFound';
import Loading from '../generalComponents/Loading';
import Footer from '../generalComponents/Footer';

function UnidadesGuardadas() {
    const rutaArchivos = "https://unit.fasta.org/data";
    useEffect(() => {
        document.title = "Inicio";
        validarUsuario();
        setDefaultValue(getParams());
        getSecciones();
        getMyData();
        getUnidadesGuardadas();
    }, []);

    const [defaultValue, setDefaultValue] = useState("");
    const [filteredUnidades, setFilteredUnidades] = useState(null);

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('ch')) {
            return params.get('ch');
        } else {
            return "";
        }
    }

    const imgSecciones = [
        { id: 1, src: '/img/Caperucitas.png' },
        { id: 2, src: null, img: 1 },
        { id: 3, src: '/img/Herederas.png' },
        { id: 4, src: '/img/Adalides.png' },
        { id: 5, src: null, img: 4 },
        { id: 6, src: '/img/Escuderos.png' },
        { id: 7, src: null, img: 6 },
        { id: 8, src: '/img/Templarios.png' },
        { id: 9, src: null, img: 8 },
    ]

    const imgs = [
        "https://concepto.de/wp-content/uploads/2015/03/paisaje-800x409.jpg",
        "https://cdn.pixabay.com/photo/2018/02/03/15/40/landscape-3127859_640.jpg",
        "https://s1.significados.com/foto/paisaje-og.jpg"
    ]

    const [unidades, setUnidades] = useState([]);
    const [unidadesCargadas, setUnidadesCargadas] = useState(false);
    const searchUnits = async (chain) => {
        const body = {
            chain: chain,
            eje: null,
            secciones: seccionesBusquedaIds,
        }
        if (chain.length >= 3) {
            setUnidadesCargadas(false);
            const uni = await buscarUnidad(getUserToken(), body);
            console.log(uni)
            setUnidadesCargadas(true);
            let arr = uni;

            arr.map((t, j) => {
                let arrStateImg = []
                t.unidades_secciones.map((r, i) => {
                    let id = r.seccionId;
                    if (imgSecciones[r.seccionId - 1].src == null) {
                        id = imgSecciones[r.seccionId - 1].img;
                    }
                    if (!arrStateImg.includes(id)) {
                        r.img = imgSecciones[id - 1].src;
                        arrStateImg.push(id);
                    } else {
                        if (id != r.seccionId) {
                            r.img = null;
                        }
                    }
                })
            })
            setUnidades(arr)
        }
    };

    //obtenerSecciones
    const [secciones, setSecciones] = useState(null)
    const getSecciones = async () => {
        const res = await obtenerSecciones(getUserToken());
        console.log(res)
        setSecciones(res.data);
    }

    const getUnidadesGuardadas = async () => {
        setUnidadesCargadas(false);
        const res = await obtenerUnidadesGuardadas(getUserToken(), getUserId());
        console.log(res)
        let m = res.map((r) => r.unidad);
        m.map((t, j) => {
            let arrStateImg = []
            t.unidades_secciones.map((r, i) => {
                let id = r.seccionId;
                if (imgSecciones[r.seccionId - 1].src == null) {
                    id = imgSecciones[r.seccionId - 1].img;
                }
                if (!arrStateImg.includes(id)) {
                    r.img = imgSecciones[id - 1].src;
                    arrStateImg.push(id);
                } else {
                    if (id != r.seccionId) {
                        r.img = null;
                    }
                }
            })
        })
        setFilteredUnidades(m);
        setUnidades(m);
        setUnidadesCargadas(true);
    }

    const SearchBar = () => {
        const handleSearchChange = async () => {
            const chain = document.getElementById(`textSearch`).value;
            handleSearch(chain)
            setDefaultValue(chain)
        };
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearchChange();
            }
        };

        return (
            <Col className=''>
                <div className='align-center-vertically '>
                    <div className='mb-1'>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="searchDiv">
                            <div onClick={() => handleSearchChange()} className='ms-2 flex a-center'>
                                <FontAwesomeIcon icon={faSearch} style={{ color: 'grey' }} />
                            </div>
                            <Form.Control
                                className='searchBar fs-14 wi-200'
                                autoComplete="off"
                                id={`textSearch`}
                                placeholder={"Buscar..."}
                                onKeyDown={handleKeyPress}
                                defaultValue={defaultValue}
                                style={{height:'45px'}}
                            />
                            <div onClick={() => handleShow()} className='ms-2 flex a-center'>
                                <FontAwesomeIcon icon={faFilter} style={{ color: 'grey' }} />
                            </div>

                        </div>
                    </div>
                </div>
            </Col>
        );
    };

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        setShowModal(true)
        setSelectedIds(seccionesBusquedaIds)
    };
    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckChange = (e, id) => {
        const { checked } = e.target;
        setSelectedIds((prevSelected) => {
            if (checked) {
                return [...prevSelected, id];
            } else {
                return prevSelected.filter((item) => item !== id);
            }
        });
    };

    const handleConfirm = () => {
        setSeccionesBusquedaIds(selectedIds);
        searchUnits(defaultValue);
        handleClose()
    };

    const handleSelectAll = (selectAll) => {
        if (selectAll) {
            setSelectedIds(secciones.map((option) => option.id));
        } else {
            setSelectedIds([]);
        }
    };

    const [seccionesBusquedaIds, setSeccionesBusquedaIds] = useState([]);
    const [myData, setMyData] = useState(null)
    const getMyData = async () => {
        const res = await obtenerMyData(getUserToken());
        console.log(res)
        setMyData(res);
        let a = [];
        res.usuarios_secciones.map((r, i) => {
            a.push(r.seccionId);
        })
        setSeccionesBusquedaIds(a);
        setSelectedIds(seccionesBusquedaIds);
    }

    /*     useEffect(() => {
            if (seccionesBusquedaIds.length > 0) {
                if (defaultValue == "") {
                    searchUnits(getParams())
                } else {
                    searchUnits(defaultValue)
                }
    
            }
        }, [seccionesBusquedaIds]); */


    const handleSearch = (searchTerm) => {
        const lowerCasedTerm = searchTerm.toLowerCase();

        const filtered = unidades.filter((unidad) =>
            unidad.nombre.toLowerCase().includes(lowerCasedTerm) ||
            unidad.etiquetas.toLowerCase().includes(lowerCasedTerm)
        );

        setFilteredUnidades(filtered);
    };

    return (
        <>
            <div className='content ps-0 pe-0 '>
                <div className='d-none d-sm-block'>

                    <div className='wi-100vw flex a-center j-spaceBt gradientBack ps-2 pe-2 navbarComponent' >
                        <div>
                            <Link to='/'>
                                <img style={{ height: '70px', marginLeft: '-25px' }} src="https://unit.fasta.org/img/logo.png" />
                            </Link>
                        </div>

                        <SearchBar />

                        <div className='flex a-center' style={{ paddingLeft: '30px' }}>
                            {getUserRole() == 1 && <Link to="/unidades"><Button style={{}} variant="light">
                                Panel de administración
                            </Button></Link>}

                            <Link to={'/unidades-guardadas'}><div className='fitContentWidth ps-3'>
                                <img style={{ height: '50px' }} src="/img/Guardar.png" />
                            </div></Link>
                            <div className='fitContentWidth'>
                                <Dropdown className='p-0'>
                                    <Dropdown.Toggle variant="" id="dropdown-custom" className="dropdown-toggle-custom p-0">
                                        <img style={{ height: '50px' }} src="/img/Perfil.png" alt="Perfil" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={logout}>Cerrar sesión</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                    <div className='ps-5 pe-5 pt-3' style={{minHeight:'90vh'}}>
                        {/* <div className="gradientBackTransparent" style={{marginTop:'70px'}}/> */}

                        {/* <Row className='p-0'>
                            <p style={{ textAlign: 'center' }}>{unidades && unidades.length} unidades encontradas</p>
                        </Row> */}
                        <Container>
                            <Row className='mt-2'>
                                {unidadesCargadas ?
                                    unidades != null && filteredUnidades && filteredUnidades.length > 0 ?
                                        filteredUnidades.map((r, i) => (
                                            <Col md={6} className='mb-3'>
                                                <div className=''>
                                                    <Link to={`unidad?id=${r.id}`}><Row className='br-15 boxShadow' style={{ maxHeight: '180px', overflow: 'hidden' }}>
                                                        <Col md={6} className='ps-3 pt-3 pb-3 flex' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <div>
                                                                <div className='fs-14 ps-2 pe-2 gradientBack fitContentWidth' style={{ borderRadius: '10px', color: 'white' }} >
                                                                    {r.unidades_ejes[0].eje.nombre}
                                                                </div>
                                                                <p className='fw-600 fs-18 mt-2 mb-1' style={{ color: 'black' }}>{r.nombre}</p>
                                                            </div>
                                                            <p className='mb-0 fs-14 grey'>{`${"#" + r.etiquetas.split("; ").join(" #")}`}</p>
                                                        </Col>
                                                        <Col md={6} className='pe-0' style={{ height: '180px', overflow: 'hidden', position: 'relative' }}>
                                                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${rutaArchivos}${r.imagen.uri}`} alt={r.nombre} />

                                                            <div style={{ position: 'absolute', bottom: '5px', right: '5px', backgroundColor: '#ffffffed', columnGap: '4px' }} className='p-1 br-15 flex a-center'>
                                                                {r.unidades_secciones.map((s, i) => s.img != null && (
                                                                    <img style={{ height: '20px' }} src={s.img} />
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row></Link>
                                                </div>
                                            </Col>
                                        ))
                                        :
                                        <div className='flex j-center a-center mt-3'>
                                            <NotFound text={"No se han encontrado unidades"} iconFa={faSadCry} />
                                        </div>

                                    :
                                    <div className='flex j-center a-center mt-3'>
                                        <Loading />
                                    </div>}
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>

                <div className='d-block d-sm-none' style={{ height: '100vh' }}>
                    <div style={{ height: '95vh', width: '95vw' }} className="p-3 flex a-center j-center">
                        <Card className='flex j-center gradientBack p-3'>
                            <img style={{ height: '70px' }} src="https://unit.fasta.org/img/logo.png" />

                            <p style={{ textAlign: 'center' }}>Instalate la app</p>

                            <a style={{ textAlign: 'center' }} href="https://play.google.com/store/apps/details?id=com.secdevs.fasta.unit&pcampaignid=web_share" target="blank"><Button variant="light">
                                Google PlayStore
                            </Button></a>

                            <a style={{ textAlign: 'center' }}><Button variant="light" className='mt-3'>
                                AppStore
                            </Button></a>
                        </Card>
                    </div>
                </div>

                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Filtrar unidades</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Seleccioná las secciones por las que quieras filtrar las unidades buscadas</p>
                        {secciones && secciones.map((r, i) => (
                            <Form.Check
                                type="checkbox"
                                id={r.id}
                                label={r.nombre}
                                onChange={(e) => handleCheckChange(e, r.id)}
                                checked={selectedIds.includes(r.id)}
                            />
                        ))}
                        {secciones && secciones.length == selectedIds.length ?
                            <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(false)}>DESELECCIONAR TODO</p>

                            : <p style={{ textAlign: 'center', color: "#46F0AF" }} className='mt-2 mb-2 pointer' onClick={() => handleSelectAll(true)}>SELECCIONAR TODO</p>

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleConfirm}
                        /* onClick={() => {
                            navigator.clipboard.writeText('Check out this song: [Link]');
                            alert('Link copiado');
                        }} */
                        >
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div >


        </>
    )
}

export default UnidadesGuardadas;